<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="成就名称" prop="username">
                    <el-input v-model="searchForm.name" style="width: 220px;" clearable
                              placeholder="请填成就名称"></el-input>
                </el-form-item>

                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div class="fill-btn" @click="achievementList">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="id" label="ID" width="60"></el-table-column>
                <el-table-column prop="title" label="成就名称" min-width="120px"></el-table-column>
                <el-table-column prop="images" label="成就图片" min-width="120px">
                    <!-- 图片的显示 -->
                    <template slot-scope="{row}">
						<el-image style="width: 40px; height: 40px" :src="row.images" :preview-src-list="[row.images]">
						</el-image>
                        <!-- <img :src="scope.row.images" min-width="80" height="70" style="width: 100%"/> -->
                    </template>
                </el-table-column>

                <el-table-column prop="created_at" min-width="120px" label="创建时间"></el-table-column>
                <el-table-column width="120" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handEditShow(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="teacherDel(row)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="成就创建" :visible.sync="showAdd">
            <div style="padding: 20px;">
                <el-form ref="form" :model="addCourse" label-width="80px">
                    <el-form-item label="成就名称">
                        <el-input v-model="addCourse.title"></el-input>
                    </el-form-item>

                    <el-form-item label="成就图片">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addCourse.images" :src="addCourse.images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="el-upload__tip" slot="tip">47.5*40</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="成就说明">
                        <el-input v-model="addCourse.introduce" type="textarea" placeholder="成就介绍"></el-input>
                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" :loading="editing" @click="createCourse" style="margin: 10px auto">保存</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="成就编辑" :visible.sync="showEdit">
            <div style="padding: 20px;">
                <el-form ref="form" :model="editForm" label-width="80px">
                    <el-form-item label="成就名称">
                        <el-input v-model="editForm.title"></el-input>
                    </el-form-item>

                    <el-form-item label="成就图片">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="editForm.images" :src="editForm.images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="成就说明">
                        <el-input v-model="editForm.introduce" type="textarea" placeholder="成就介绍"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" :loading="editing" @click="saveCourse" style="margin: 10px auto">保存</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                form: {
                    nickname: '',
                    images:''
                },
                editForm: {
                    title:'',
                    images:'',
                    introduce:'',
                },
                currentId: '',
                tableData: [],
                typeList: [],
                addCourse: {
					title:'',
					images:'',
					introduce:'',
				},
                teacherAll: [],
            }
        },
        created() {
            this.achievementList();

        },
        computed: {
            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.achievementList();
            },
        },
        methods: {
            //头像
            handleAvatarSuccess(res, file) {

                console.log(res);;
                if(res.code==400){

                    this.$message.error(res.msg)
                }else {
                    this.editForm.images = res.url;
                    this.addCourse.images = res.url;
                    this.$forceUpdate();
                }

            },

            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                //
                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG,png 格式!');
                // }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }

                return  isLt2M;
            },
            handShowAdd() {
                try {
                	this.showAdd = true;
                	for (let i in this.addCourse) {
                		this.addCourse[i] = '';
                	}
                	this.$refs['form'].resetFields();           	
                } catch {}
            },
            handEditShow(row) {
                let roles = [];
                // role.forEach((item, index) => {
                // 	roles.push(item.role_id)
                // });
                //
                // this.editForm.role_ids = roles;
                this.editForm = row;
                // this.editForm.account = account;
                // // this.editForm.status = status.toString();
                // this.currentId = id;
                this.showEdit = true;
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取列表
            async achievementList() {
                let [e, data] = await this.$api.achievementList(this.searchForm);
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },

            //导师列表
            async getTeacherAll(query) {
                let [e, data] = await this.$api.getTeacherListAll({name: query});
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.teacherAll = data.data;
                }

            },
            @delToast()
            async teacherDel({id}) {
                let [e, data] = await this.$api.achievementDel({id});
                if (e) return;
                if (data.code === 200) {
                    this.achievementList();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },

            //添加成就
            async createCourse() {
                this.editing = true;
                let [e, data] = await this.$api.achievementCreate(this.addCourse);
                if (e) {this.editing = false;return};
                if (data.code === 200) {
                    this.achievementList();
                    this.showAdd = false;

                    this.$message.success('添加成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            },
            //编辑

            async saveCourse() {
                this.editing = true;
                let [e, data] = await this.$api.achievementSave(this.editForm);
                if (e){this.editing = false; return};
                if (data.code === 200) {
                    this.showEdit = false;
                    this.achievementList();
                    this.$message.success('保存成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            }
        },
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
